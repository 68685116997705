<template>
  <b-modal
    ref="export-excel-posm"
    v-model="isShown"
    v-show="isShown"
    title="Xuất Excel theo Campaign"
  >
    <b-row>
      <b-col>
        <b-form-group
          label="Chọn Campaign để xuất Excel:"
          label-for="input-campaign"
        >
          <AutoSuggest
            :model="apiParams.search"
            v-model="campaignId"
            required
            placeholder="tên Campaign"
            :suggestions="campaignList"
            @select="onSelectedCampaign"
            @change="onInputChangeCampaign"
            suggestion-name="name"
            :limit="100"
          ></AutoSuggest>
          <p class="text-muted">
            Đây là những Campaign mà đã có nhận được kết quả báo cáo POSM từ cửa
            hàng
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-primary"
        @click="fetchPosmCampaignExcel"
        :disabled="disableState"
      >
        <b-spinner v-if="disableState" small></b-spinner>
        Xuất Excel
      </button>
    </template>
  </b-modal>
</template>
<script>
import axios from 'axios';
import { cmdUrl } from '@/utils/apiUrl';
import fileDownload from '@/utils/file-download';
import { makeToastFaile } from '@/utils/common';
import AutoSuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import ApiService from '@/core/services/api.service';
import CampaignSuggestion from '@/view/components/posm/campaign/models/CampaignSuggestion';
import debounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';

export default {
  components: { AutoSuggest },
  props: ['show'],
  data() {
    return {
      modalIsShown: false,
      campaignId: null,
      campaignList: [],
      filteredCampaignList: [],
      isDownloading: false,
      apiParams: {
        search: '',
      },
    };
  },

  methods: {
    fetchPosmCampaignExcel() {
      this.isDownloading = true;
      axios
        .get(`${cmdUrl.Campaign.root}/export-excel/${this.campaignId}`, {
          responseType: 'blob',
        })
        .then((response) => {
          const fileName = fileDownload.getFileName(response);
          fileDownload.createFile(response.data, fileName);
          this.isDownloading = false;
        })
        .catch((err) => {
          this.isDownloading = false;
          if (!err.response) {
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },

    fetchCampaigns() {
      ApiService.get(
        `${cmdUrl.Campaign.root}/export-excel-submitted?search=${this.apiParams.search}`,
      )
        .then(({ data }) => {
          this.campaignList = data.data.map(
            (campaign) => new CampaignSuggestion(campaign.name, campaign.id),
          );
        })
        .catch((err) => makeToastFaile(err));
    },

    onSelectedCampaign(option) {
      this.campaignId = option.item.id;
    },
    onInputChangeCampaign(text) {
      this.apiParams.search = text;
      this.campaignList = [];
      this.filteredCampaignList = [];
      this.debounceInput();
    },
    debounceInput: debounce(function() {
      this.fetchCampaigns();
    }, TIME_TRIGGER),
  },
  computed: {
    isShown: {
      get() {
        return this.modalIsShown || this.show;
      },
      set(newVal) {
        this.modalIsShown = newVal;
        this.$emit('update-show', newVal);
      },
    },
    disableState() {
      return !this.campaignId || this.isDownloading;
    },
  },
  mounted() {
    this.fetchCampaigns();
  },
};
</script>
