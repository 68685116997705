<template>
  <KTCodePreview v-bind:title="'Danh sách các Campaign POSM'">
    <template v-slot:toolbar>
      <b-row>
        <b-dropdown
          size="sm"
          id="dropdown-1"
          v-if="checkPermissions(['CAMPAIGN_EXCEL_EXPORT'])"
        >
          <template slot="button-content">
            <i style="font-size: 1rem" class="fas fa-cog"></i>
            <span class="font-weight-bolder">Thao tác</span>
          </template>
          <b-dropdown-item @click="showExportExcelModalClickHandler">
            <span>
              <i style="font-size: 1rem" class="far fa-file-excel"></i>
              &nbsp; Xuất Excel</span
            >
          </b-dropdown-item>
        </b-dropdown>
        <router-link
          :to="{
            name: 'posm-form-campaigns-add',
          }"
          v-if="checkPermission('CAMPAIGN_INSERT')"
        >
          <button
            type="button"
            class="btn btn-sm font-weight-bolder btn-primary ml-2"
          >
            <i style="font-size:1rem" class="flaticon2-add-1 btn-add"></i>
            Tạo mới
          </button>
        </router-link>
      </b-row>
    </template>
    <template v-slot:preview>
      <div>
        <b-col cols="12" class="py-8">
          <ExportExcelModal
            :show="showExportExcelModal"
            @update-show="updateModalState"
          />
          <b-row>
            <b-col cols="12">
              <b-table
                bordered
                :items="campaigns"
                :fields="fields"
                :per-page="apiParams.perPage"
                :current-page="currentPage"
                class="table-vertical-center"
              >
                <template #cell(remainingDays)="row" class='text-center'>
                  <span class='badge badge-success'>
                    Đang hoạt động
                  </span>
                </template>
                <template #cell(actions)="row">
                  <div class="d-flex justify-content-center">
                    <b-dropdown size="sm" id="dropdown-left" no-caret right>
                      <template slot="button-content">
                        <i
                          style="font-size: 1rem; padding-right: 0px"
                          class="flaticon2-settings"
                        ></i>
                      </template>
                      <b-dropdown-item
                        @click="editCampaignHandler(row)"
                        v-if="checkPermission('CAMPAIGN_UPDATE')"
                      >
                        <span style="color: #3f4254; font-size: 12px">
                          <i style="font-size: 1rem" class="flaticon2-pen"></i>
                          &nbsp; Chỉnh sửa
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="deleteCampaignHandler(row)"
                        v-if="checkPermission('CAMPAIGN_DELETE')"
                      >
                        <span style="color: #3f4254; font-size: 12px">
                          <i
                            style="font-size: 1rem; color: #d33"
                            class="flaticon2-rubbish-bin-delete-button"
                          ></i>
                          &nbsp; Xóa
                        </span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
              <b-pagination
                :per-page="apiParams.perPage"
                :total-rows="campaigns.length"
                v-model="currentPage"
                align="right"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </div>
      <b-card no-body class="full-width"></b-card>
    </template>
  </KTCodePreview>
</template>
<style scoped lang="scss">
@import '@/assets/sass/components/_variables.bootstrap.scss';

.add-btn {
  font-size: $font-size-base;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { fields } from '@/view/pages/posm/campaign/table-setup';
import CampaignTableData from '@/view/pages/posm/campaign/models/CampaignTableData';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import { formatDate, getRemainingDays } from '@/utils/date';
import { makeToastFaile } from '@/utils/common';
import { DATETIME_FORMAT } from '@/utils/constants';
import moment from 'moment/moment';
import ExportExcelModal from '@/view/components/posm/campaign/ExportExcelModal.vue';
import localData from '@/utils/saveDataToLocal';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';

export default {
  components: {
    KTCodePreview,
    ExportExcelModal,
  },
  data() {
    return {
      fields,
      campaigns: [],
      currentPage: 1,
      showExportExcelModal: false,
      apiParams: {
        page: 1,
        perPage: 5,
      },
    };
  },
  methods: {
    fetchCampaigns(params) {
      ApiService.query(cmdUrl.Campaign.root, {
        params: params ? params : { ...this.apiParams },
      })
        .then(({ data }) => {
          this.campaigns = data.data.map((item) => {
            const now = moment().format(DATETIME_FORMAT);
            const formattedEndDate = formatDate(item.endDate, DATETIME_FORMAT);
            const remainingDays = getRemainingDays(now, formattedEndDate);

            return new CampaignTableData(
              item.id,
              item.name,
              formatDate(item.startDate, DATETIME_FORMAT),
              formatDate(item.endDate, DATETIME_FORMAT),
              remainingDays,
            );
          });
        })
        .catch((err) => {
          makeToastFaile(err.message);
        });
    },
    editCampaignHandler(row) {
      const id = row.item.id;
      this.$router.push({ name: 'posm-form-campaigns-edit', params: { id } });
    },
    deleteCampaignHandler(row) {
      const id = row.item.id;
      const confirmDelete = confirm('Bạn có chắc chắn muốn xóa campaign này?');

      if (!confirmDelete) {
        return;
      }
      ApiService.delete(`${cmdUrl.Campaign.root}/${id}`)
        .then(() => {
          this.fetchCampaigns();
        })
        .catch((err) => {
          makeToastFaile(err.message);
        });
    },

    showExportExcelModalClickHandler() {
      this.showExportExcelModal = true;
    },
    updateModalState(childComponentVal) {
      this.showExportExcelModal = childComponentVal;
    },
    checkPermission: function(condition) {
      return localData.checkPermission(condition);
    },
    checkPermissions: function(conditions) {
      return localData.checkPermissions(conditions);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Quản lý Campaign' }]);
    this.fetchCampaigns();
  },
};
</script>
